import NavLinkComponent from 'components/NavLink';
import styled from '@emotion/styled';

type Props = {
  floatRight?: boolean;
  isBlock?: boolean;
  underline?: boolean;
};

const NavLink = styled(NavLinkComponent)<Props>(
  ({ floatRight = false, isBlock = false, underline = true, theme }) => ({
    color: theme.colors.gray['600'],
    cursor: 'pointer',
    float: floatRight ? 'right' : 'none',
    fontSize: theme.fonts.size['14'],
    fontWeight: theme.fonts.weight.regular,
    lineHeight: theme.fonts.lineHeight['16'],
    textDecoration: underline ? 'underline' : 'none',
    transition: 'all 300ms ease-in-out',

    '&:hover': {
      textDecoration: underline ? 'underline' : 'none',
    },
    width: isBlock ? '100%' : 'auto',
  }),
);

export default NavLink;
