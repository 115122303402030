import H6 from 'primitives/Typography/Headings/H6';
import styled from '@emotion/styled';

const ProfileName = styled(H6)(({ theme }) => ({
  color: theme.colors.gray['600'],
  fontWeight: theme.fonts.weight.regular,
  letterSpacing: 'initial',
  margin: '0 1rem',
  maxWidth: '11rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'initial',
  whiteSpace: 'nowrap',
}));

export default ProfileName;
