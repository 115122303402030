import styled from '@emotion/styled';

const CloseButton = styled('button')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.transparent.primary,
  border: `1px solid ${theme.colors.gray['300']}`,
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'flex',
  height: '3rem',
  justifyContent: 'center',
  marginLeft: '1.5rem',
  minWidth: '3rem',
  width: '3rem',

  '&:hover': {
    borderColor: theme.colors.gray['500'],
  },

  svg: {
    height: '1.3rem',
    width: '1.3rem',
    minWidth: '1.3rem',
  },
}));

export default CloseButton;
