import styled from '@emotion/styled';

const HeaderLeft = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flex: '0 0 auto',

  '> :first-of-type': {
    marginRight: '0.5rem',
  },
});

export default HeaderLeft;
