import Dropdown from 'components/Dropdown';
import styled from '@emotion/styled';
import TriggerWrapper from 'components/Dropdown/primitives/TriggerWrapper';

const ProfileDropdown = styled(Dropdown)({
  [TriggerWrapper.toString()]: {
    opacity: 1,
  },
});

export default ProfileDropdown;
