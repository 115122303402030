import SearchForm from './SearchForm';
import { closeModal, openModal } from 'state/UI/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ConnectedModals } from 'state/UI/constants';
import { createStructuredSelector } from 'reselect';
import { getAbTests } from 'state/User/selectors';
import {
  getCustomRadioEnabled,
  getInternationalPlaylistRadioEnabled,
  getLiveRadioEnabled,
  getNewSearchEnabled,
  getOnDemandEnabled,
} from 'state/Features/selectors';
import { getIsSearchOpen } from 'state/UI/selectors';
import { getSearchFilter, getSearchQuery } from 'state/Search/selectors';
import { localize } from 'redux-i18n';
import { setSearchFilter, setSearchQuery } from 'state/Search/actions';
import { showPlaylistSelector } from 'state/Entitlements/selectors';

export default compose(
  localize('translate'),
  connect(
    createStructuredSelector({
      abTestGroups: getAbTests,
      customRadioEnabled: getCustomRadioEnabled,
      liveRadioEnabled: getLiveRadioEnabled,
      newSearchEnabled: getNewSearchEnabled,
      onDemandEnabled: getOnDemandEnabled,
      searchFilter: getSearchFilter,
      isSearchOpen: getIsSearchOpen,
      searchQuery: getSearchQuery,
      showPlaylists: showPlaylistSelector,
      internationalPlaylistRadioEnabled: getInternationalPlaylistRadioEnabled,
    }),
    {
      setSearchFilter,
      openSearch: () =>
        openModal({
          id: ConnectedModals.Search,
          context: null,
        }),
      closeModal,
      setSearchQuery,
    },
  ),
)(SearchForm);
