import styled from '@emotion/styled';

const MenuItem = styled('li')(({ theme }) => ({
  display: 'inline-block',
  marginRight: '3rem',
  whiteSpace: 'nowrap',

  a: {
    color: theme.colors.gray['400'],
    position: 'relative',
    textDecoration: 'none',

    '&.active': {
      borderBottom: `0.3rem solid ${theme.colors.red.primary}`,
      paddingBottom: '1.5rem',
    },

    '&:hover': {
      color: theme.colors.gray['600'],
    },
  },
}));

export default MenuItem;
