import styled from '@emotion/styled';

const H6 = styled('h6')(({ theme }) => ({
  color: theme.colors.gray['600'],
  fontSize: theme.fonts.size['14'],
  fontWeight: theme.fonts.weight.medium,
  letterSpacing: '0.15rem',
  lineHeight: theme.fonts.lineHeight['18'],
  margin: '0.1rem 0',
  textTransform: 'uppercase',
  transition: 'all 300ms ease-in-out',
}));

export default H6;
