import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { Selector } from 'state/types';
import { State } from './types';

export const getSearch: Selector<State> = createSelector(
  state => state,
  state => state.search || get(state, 'search', {}),
);

export const getSearchQuery: Selector<string> = createSelector(
  getSearch,
  search => get(search, 'searchQuery', ''),
);

export const getSearchFilter: Selector<string> = createSelector(
  getSearch,
  search => get(search, 'searchFilter', ''),
);
