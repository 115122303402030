import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Input = styled('input')({
  borderRadius: '3.6rem',
  height: '3.2rem',
  lineHeight: '3.2rem',
  paddingLeft: '3.5rem',
});

// We need two different inputs in order to shorten the placeholder
// on the input on mobile.
export const MobileInput = styled(Input)({
  [mediaQueryBuilder('(min-width: 1025px)')]: {
    display: 'none',
  },
});

export const DesktopInput = styled(Input)(({ theme }) => ({
  [mediaQueryBuilder(theme.mediaQueries.max.width['1024'])]: {
    display: 'none',
  },
}));
