import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  isSearchOpen: boolean;
};

const HeaderMobile = styled('div')<Props>(
  ({ isSearchOpen = false, theme }) => ({
    alignItems: 'center',
    display: 'none',
    justifyContent: 'space-between',
    width: '100%',

    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      display: isSearchOpen ? 'none' : 'flex',
    },
  }),
);

export default HeaderMobile;
