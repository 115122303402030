import { useEffect } from 'react';

type Props<Element extends HTMLElement> = {
  element?: Element;
  event: string;
  handler: (e: Event) => void;
  passive?: boolean;
};

function useEvent<Element extends HTMLElement>({
  element,
  event,
  handler,
  passive = false,
}: Props<Element>): void {
  useEffect((): (() => void) => {
    if (!__CLIENT__) return () => {};

    const elementToUse = element || window;

    // initiate the event handler
    elementToUse.addEventListener(event, handler, passive);

    // this will clean up the event every time the component is re-rendered
    return function cleanup(): void {
      elementToUse.removeEventListener(event, handler);
    };
  });
}

export default useEvent;
