import AccountIcon from 'styles/icons/AccountIcon';
import DarkModeSwitch from 'components/DarkModeSwitch/DarkModeSwitch';
import ExpandDown from 'styles/icons/ExpandDown';
import HeaderRightStyles from './primitives/HeaderRight';
import IconWrapper from './primitives/IconWrapper';
import NavLink from 'components/NavLink';
import ProfileButton from './primitives/ProfileButton';
import ProfileDropdown from './primitives/ProfileDropdown';
import ProfileName from './primitives/ProfileName';
import ProfilePic from 'components/ProfilePic/ProfilePic';
import SearchForm from 'components/SearchForm';
import ShouldShow from 'components/ShouldShow';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { MouseEvent } from 'react';

type Props = {
  darkModeAvailable: boolean;
  displayName: string;
  isInitialRender: boolean;
  isLoggedOut: boolean;
  logoutAndStartAnonymousSession: (args: {
    forced: boolean;
    noRedirect: boolean;
  }) => Promise<void>;
  onLoginClick: (event: MouseEvent<HTMLDivElement>) => void;
  isSearchOpen: boolean;
  showLoginInNav: boolean;
};

function HeaderRight({
  darkModeAvailable,
  displayName,
  isInitialRender,
  isLoggedOut,
  logoutAndStartAnonymousSession,
  onLoginClick,
  isSearchOpen,
  showLoginInNav,
}: Props) {
  const translate = useTranslate();

  const hasUser = !isLoggedOut && !isInitialRender;

  const trackUserLoggedOut = () => {
    /**
     * Track user logging out
     */
    logoutAndStartAnonymousSession({ forced: false, noRedirect: false });
  };

  const userMenuBtn = (
    <ShouldShow shouldShow={hasUser}>
      <ProfileButton>
        <ProfilePic size={30} />
        <ProfileName data-test="header-profile-name">{displayName}</ProfileName>
        <ExpandDown />
      </ProfileButton>
    </ShouldShow>
  );
  return (
    <HeaderRightStyles data-test="header-right" isSearchOpen={isSearchOpen}>
      <ShouldShow shouldShow={!isInitialRender}>
        <SearchForm />
      </ShouldShow>
      <ShouldShow shouldShow={darkModeAvailable}>
        <DarkModeSwitch />
      </ShouldShow>
      <ShouldShow shouldShow={hasUser && !isSearchOpen}>
        <ProfileDropdown extendDown triggerBtn={userMenuBtn}>
          <NavLink
            dataTest="profile-dropdown-settings"
            title={translate('Settings')}
            to="/my/settings/"
          >
            {translate('Settings')}
          </NavLink>
          <NavLink
            dataTest="profile-dropdown-logout"
            onClick={trackUserLoggedOut}
            title={translate('Log Out')}
            underline
          >
            {translate('Log Out')}
          </NavLink>
        </ProfileDropdown>
      </ShouldShow>
      <ShouldShow shouldShow={showLoginInNav && !hasUser && !isSearchOpen}>
        <IconWrapper onClick={onLoginClick} title={translate('Account')}>
          <AccountIcon data-test="account-icon" />
        </IconWrapper>
      </ShouldShow>
    </HeaderRightStyles>
  );
}

export default HeaderRight;
