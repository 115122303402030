import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  pushDown?: boolean;
  isSearchOpen?: boolean;
};

const Wrapper = styled('div')<Props>(
  ({ pushDown = false, theme, isSearchOpen }) => ({
    display: 'flex',
    height: theme.dimensions.headerHeight,
    margin: '0 auto',
    maxWidth: '142rem',
    padding: '0 1.5rem',
    position: 'relative',
    width: '100%',
    flexShrink: 0,
    [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]:
      isSearchOpen ?
        {
          height: '100%',
        }
      : {},

    ...(pushDown ?
      {
        backgroundColor: theme.colors.white.primary!,
        justifyContent: 'center',
        maxWidth: 'unset',
        padding: 0,
      }
    : {}),
  }),
);

export default Wrapper;
