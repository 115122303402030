import styled from '@emotion/styled';

const ProfileButton = styled('button')({
  alignItems: 'center',
  background: 'transparent',
  border: 'none',
  display: 'flex',
  outline: 'none',
});

export default ProfileButton;
