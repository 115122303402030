import CloseButton from './primitives/CloseButton';
import CloseIcon from 'styles/icons/CloseIcon';
import InputWrapper from './primitives/InputWrapper';
import MagnifyingIcon from './primitives/MagnifyingIcon';
import SearchFormStyles from './primitives/SearchForm';
import SelectBox from 'web-ui/templates/FormElements/SelectBox';
import ShouldShow from 'components/ShouldShow';
import { debounce } from 'lodash-es';
import { DesktopInput, MobileInput } from './primitives/Input';
import { getSearchFilters } from './helpers';
import { PureComponent } from 'react';
import { SEARCH_FILTERS } from 'state/Search/constants';

export default class SearchInput extends PureComponent {
  state = {
    searchQuery: '',
  };

  debouncedSetSearchQuery = debounce(
    query => {
      const { setSearchQuery } = this.props;
      setSearchQuery({ query });
    },
    600,
    { maxWait: 1000 },
  );

  componentDidMount() {
    const { isSearchOpen, searchQuery } = this.props;
    if (isSearchOpen && this.input) this.input.focus();
    if (searchQuery) this.setState({ searchQuery });
  }

  componentDidUpdate(prevProps) {
    const { isSearchOpen, searchFilter, translate } = this.props;
    const searchFilters = getSearchFilters(translate, SEARCH_FILTERS);
    if (isSearchOpen && !prevProps.isSearchOpen) {
      this.input.focus();
    }

    if (this.filter && searchFilter !== prevProps.searchFilter) {
      this.filter.setState({
        selectedOption:
          searchFilters.find(({ value }) => value === searchFilter) ||
          searchFilters[0],
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keydown', this.onSearchCloseKeyDown);
  }

  onSearch = ev => {
    const query = (ev && ev.target.value) || '';
    this.setState({ searchQuery: query });
    this.debouncedSetSearchQuery(query);
    this.openSearch();
  };

  onSearchFocus = () => {
    window.addEventListener('keydown', this.onKeyDown);
    this.openSearch();
  };

  onSearchBlur = () => {
    window.removeEventListener('keydown', this.onKeyDown);
  };

  onKeyDown = ev => {
    if (ev.keyCode === 38 || ev.keyCode === 40 || ev.keyCode === 13) {
      this.input.blur();
    }
  };

  onSubmit = ev => {
    ev.preventDefault();
    this.openSearch();
  };

  onFilterChange = filter => {
    const { setSearchFilter } = this.props;
    setSearchFilter({ filter });
    this.filter.blur();
  };

  onSearchCloseFocus = () => {
    window.addEventListener('keydown', this.onSearchCloseKeyDown);
  };

  onSearchCloseBlur = () => {
    window.removeEventListener('keydown', this.onSearchCloseKeyDown);
  };

  onSearchCloseClick = () => {
    this.closeSearch();
  };

  onSearchCloseKeyDown = ev => {
    if (ev.keyCode === 13) {
      this.closeSearch();
    }
  };

  closeSearch = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  openSearch = () => {
    const { openSearch, setSearchFilter, isSearchOpen } = this.props;

    if (!isSearchOpen) {
      setSearchFilter({ filter: '' });
      openSearch();
    }
  };

  getPlaceholder() {
    const { customRadioEnabled, liveRadioEnabled, isSearchOpen, translate } =
      this.props;
    if (!isSearchOpen) {
      return '';
    }

    if (customRadioEnabled) {
      return translate('artists, stations or podcasts');
    }

    if (liveRadioEnabled) {
      return translate('stations or podcasts');
    }

    return translate('podcasts');
  }

  renderInput(Input, placeholder) {
    return (
      <Input
        aria-label="Search"
        data-test="header-search-input"
        onBlur={this.onSearchBlur}
        onChange={this.onSearch}
        onFocus={this.onSearchFocus}
        placeholder={placeholder}
        ref={input => {
          this.input = input;
        }}
        type="text"
        value={this.state.searchQuery}
      />
    );
  }

  render() {
    const {
      showPlaylists,
      customRadioEnabled,
      internationalPlaylistRadioEnabled,
      liveRadioEnabled,
      onDemandEnabled,
      searchFilter,
      isSearchOpen,
      translate,
    } = this.props;
    const searchFilters = getSearchFilters(translate, SEARCH_FILTERS);

    const filters = searchFilters.filter(({ value }) => {
      switch (value) {
        case SEARCH_FILTERS.COLLECTION:
          return showPlaylists || internationalPlaylistRadioEnabled;
        case SEARCH_FILTERS.SONG:
        case SEARCH_FILTERS.ARTIST:
          return customRadioEnabled;
        case SEARCH_FILTERS.ALBUM:
        case SEARCH_FILTERS.BUNDLE:
          return onDemandEnabled;
        case SEARCH_FILTERS.LIVE:
          return liveRadioEnabled;
        default:
          return true;
      }
    });
    const filterOption = searchFilters.find(
      filter => filter.value === searchFilter,
    );
    return (
      <SearchFormStyles
        data-test="header-search-form"
        isSearchOpen={isSearchOpen}
        onSubmit={this.onSubmit}
      >
        <InputWrapper isSearchOpen={isSearchOpen}>
          <MagnifyingIcon />
          {this.renderInput(DesktopInput, this.getPlaceholder())}
          {this.renderInput(MobileInput, translate('search'))}
        </InputWrapper>
        <ShouldShow shouldShow={isSearchOpen}>
          <>
            <ShouldShow
              shouldShow={
                filters.filter(({ value }) => value !== SEARCH_FILTERS.ALL)
                  .length > 1
              }
            >
              <SelectBox
                dataTest="search-form"
                name="Filter"
                onChange={this.onFilterChange}
                options={filters}
                ref={filter => {
                  this.filter = filter;
                }}
                selectClasses="short"
                selectedOption={filterOption || filters[0]}
                selectStyles={{ width: '50%' }}
                tabindex={4}
              />
            </ShouldShow>
            <CloseButton
              aria-label="Close search form"
              data-test="search-form-close-button"
              onBlur={this.onSearchCloseBlur}
              onClick={this.onSearchCloseClick}
              onFocus={this.onSearchCloseFocus}
            >
              <CloseIcon />
            </CloseButton>
          </>
        </ShouldShow>
      </SearchFormStyles>
    );
  }
}
