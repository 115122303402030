import styled from '@emotion/styled';

type Props = {
  pushDown?: boolean;
  isSearchOpen?: boolean;
};

const AdWrapper = styled('div')<Props>({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  padding: '0.5rem',
});

export default AdWrapper;
