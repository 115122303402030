import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import type { Theme } from 'styles/themes/default';

export type Props = {
  headerPosition?: 'fixed' | 'relative';
  listenInAppVisible?: boolean;
  pushDown?: boolean;
  isSearchOpen: boolean;
  isAdsEnabled: boolean;
};

export const sizeStyles = ({
  pushDown,
  isSearchOpen,
  theme,
  isAdsEnabled,
}: Props & { theme: Theme }) => {
  return {
    borderBottom: `2px solid ${theme.colors.gray['300']}`,
    height:
      pushDown && isAdsEnabled ?
        `calc(${theme.dimensions.headerHeight} + 11.2rem)`
      : theme.dimensions.headerHeight,
    marginTop: 0,
    width: '100%',
    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      height:
        isAdsEnabled && !isSearchOpen ?
          `calc(${theme.dimensions.headerHeight} + 6.2rem)`
        : theme.dimensions.headerHeight,
    },
    [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]:
      isSearchOpen ?
        {
          height: '9.8rem',
        }
      : {},
  };
};

const Container = styled('div')<Props>(
  ({
    listenInAppVisible = false,
    pushDown = false,
    isSearchOpen = false,
    theme,
    isAdsEnabled,
  }) => ({
    backgroundColor: theme.colors.white.primary!,
    position: 'fixed',
    top: 0,
    zIndex: theme.zIndex.header,

    ...(listenInAppVisible ?
      {
        '.tabs': {
          marginTop: 0,
        },

        '.listen-in-app': {
          top: 0,
        },
      }
    : {}),

    ...((pushDown || isAdsEnabled) && !isSearchOpen ?
      {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }
    : {}),

    ...sizeStyles({
      listenInAppVisible,
      pushDown,
      isSearchOpen,
      theme,
      isAdsEnabled,
    }),
  }),
);

export default Container;
