import styled from '@emotion/styled';

const HeaderMiddle = styled('div')({
  flex: 'auto',
  position: 'relative',
  textAlign: 'left',
  width: '100%',
});

export default HeaderMiddle;
