import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Menu = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  margin: 0,
  padding: '0 2rem',

  '.btn': {
    paddingLeft: 0,
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['1280'])]: {
    justifyContent: 'center',
  },
}));

export default Menu;
