import styled from '@emotion/styled';

const SwitchStyle = styled('span')(({ theme }) => ({
  ':after': {
    backgroundColor: theme.colors.white.primary,
    border: `1px solid ${theme.colors.gray.tertiary}`,
    borderRadius: '50%',
    content: `''`,
    height: '1.7rem',
    left: '-2px',
    position: 'absolute',
    top: '-1px',
    transition: 'top .5s',
    width: '1.7rem',
  },
  backgroundColor: theme.colors.gray[200],
  border: `1px solid ${theme.colors.gray[300]}`,
  borderRadius: '1rem',
  height: '3rem',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '1.5rem',
}));

export default SwitchStyle;
