import Container from 'components/DarkModeSwitch/primitives/Container';
import SwitchInput from 'components/DarkModeSwitch/primitives/SwitchInput';
import SwitchStyle from 'components/DarkModeSwitch/primitives/SwitchStyle';
import useDark from 'contexts/Theme/useDark';

function DarkModeSwitch() {
  const [isDark, setIsDark] = useDark();
  return (
    <Container>
      <SwitchInput onClick={() => setIsDark(!isDark)} type="checkbox" />
      <SwitchStyle />
    </Container>
  );
}

export default DarkModeSwitch;
