import FilledButton from 'primitives/Buttons/FilledButton';
import HeaderLeftStyles from 'components/Header/primitives/HeaderLeft';
import Logo from 'components/Logo/Logo';
import NavLink from 'primitives/Typography/Links/NavLink';
import ShouldShow from 'components/ShouldShow';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { addParams } from 'utils/url';
import { closeModal } from 'state/UI/actions';
import { getIsSearchOpen } from 'state/UI/selectors';
import { HEADER_UPGRADE_BUTTON } from 'modules/Analytics/constants/upsellFrom';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  allAccessPreview: boolean;
  hasUpgrade: boolean;
  isInitialRender: boolean;
  upgradeUrl?: string;
};

function HeaderLeft({
  allAccessPreview,
  hasUpgrade,
  isInitialRender,
  upgradeUrl,
}: Props) {
  const translate = useTranslate();

  const upgradeLink = addParams(upgradeUrl || '/upgrade', {
    upsellFrom: HEADER_UPGRADE_BUTTON,
  });

  const dispatch = useDispatch();
  const isSearchOpen = useSelector(getIsSearchOpen);

  const headerCtaText =
    allAccessPreview ? translate('Subscribe') : translate('Upgrade');
  return (
    <HeaderLeftStyles data-test="header-left">
      <Logo dark onClick={() => dispatch(closeModal())} />

      <ShouldShow
        shouldShow={
          (hasUpgrade || allAccessPreview) && !isInitialRender && !isSearchOpen
        }
      >
        <NavLink
          title={translate('Upgrade Now')}
          to={upgradeLink}
          underline={false}
        >
          <FilledButton
            aria-label={translate('Upgrade Now')}
            data-test="upgrade-now"
            size="small"
          >
            {headerCtaText}
          </FilledButton>
        </NavLink>
      </ShouldShow>
    </HeaderLeftStyles>
  );
}

export default HeaderLeft;
