import { SEARCH_FILTERS } from 'state/Search/constants';

export function getSearchFilters(translate, isNewSearch) {
  return [
    { title: translate('All'), value: SEARCH_FILTERS.ALL },
    { title: translate('Live Stations'), value: SEARCH_FILTERS.LIVE },
    { title: translate('Artists'), value: SEARCH_FILTERS.ARTIST },
    {
      title: translate('Albums'),
      value: isNewSearch ? SEARCH_FILTERS.BUNDLE : SEARCH_FILTERS.ALBUM,
    },
    { title: translate('Songs'), value: SEARCH_FILTERS.SONG },
    { title: translate('Playlists'), value: SEARCH_FILTERS.COLLECTION },
    { title: translate('Podcasts'), value: SEARCH_FILTERS.PODCAST },
  ];
}
