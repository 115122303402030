import styled from '@emotion/styled';
import { Props, sizeStyles } from './Container';

const PlaceHolder = styled('div')<Props>(
  ({
    listenInAppVisible = false,
    pushDown = false,
    isSearchOpen = false,
    theme,
    isAdsEnabled,
  }) => ({
    flexShrink: 0,
    ...sizeStyles({
      listenInAppVisible,
      pushDown,
      isSearchOpen,
      theme,
      isAdsEnabled,
    }),
  }),
);

export default PlaceHolder;
