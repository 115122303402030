import styled from '@emotion/styled';

const IconWrapper = styled('div')({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  transform: 'translateY(-0.2rem)',

  svg: {
    marginLeft: '0.5rem',
  },
});

export default IconWrapper;
