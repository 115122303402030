import MagnifyingGlass from 'styles/icons/MagnifyingGlass';
import styled from '@emotion/styled';

const MagnifyingIcon = styled(MagnifyingGlass)({
  left: 0,
  position: 'absolute',
  transform: 'translate(1rem, 0.5rem)',
});

export default MagnifyingIcon;
