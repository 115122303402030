import styled from '@emotion/styled';
import SwitchInput from 'components/DarkModeSwitch/primitives/SwitchInput';
import SwitchStyle from 'components/DarkModeSwitch/primitives/SwitchStyle';

const Container = styled('label')({
  [`${SwitchInput.toString()}:checked ~ ${SwitchStyle.toString()}`]: {
    '&::after': {
      top: '1.2rem',
    },
  },
  cursor: 'pointer',
  paddingLeft: '2.1rem',
  position: 'relative',
});

export default Container;
