import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  isSearchOpen: boolean;
};

const HeaderRight = styled('div')<Props>(({ isSearchOpen = false, theme }) => ({
  display: 'flex',
  flex: '0 0 auto',
  maxHeight: '3rem',
  textAlign: 'right',

  '.image': {
    borderRadius: '50%',
    height: '100%',
    maxHeight: '3rem',
    maxWidth: '3rem',
    overflow: 'hidden',
    width: '3rem',
  },

  ...(isSearchOpen ?
    {
      [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
        width: '100%',
      },
    }
  : {}),
}));

export default HeaderRight;
