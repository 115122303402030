import styled from '@emotion/styled';

const SwitchInput = styled('input')({
  height: '2rem',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '1rem',
});

export default SwitchInput;
